body {
  margin: 0;
  font-family:"Open Sans",  Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sui-layout-body{
  background: #fff;
}

.sui-layout-body__inner{
  width: 100%;
  max-width: 90vw;
}

.header {
 height: 80px;
  background: #F8F9FA;
  border-bottom: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.search-input {
  height: 45px;
}

 .search-box {
  width: 858px;
  justify-content: flex-end;
  padding: 0 0 0 40px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
}


 .search-box__wrapper {
  background-color: #fafbfd;
  border-radius: 6px;
  padding: 0;
  border: none;
}

 .sui-search-box__text-input {
  border: 1px solid #CED4DA;
  border-right: none;
  border-radius: 6px 0 0 6px;
  background-color: #FAFBFD;
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
}

.sui-search-box__text-input:focus {
  box-shadow: none;
  border:   2px solid  #C80432;
  border-right:none ;
}

button.sui-search-box__submit.search-btn {
  background: #C80432;
  width: 48px;
  border-radius: 0 8px 8px 0;
  margin: 0;
  box-shadow: none;
  border: 1px solid #C80432;
}

button.sui-search-box__submit.search-btn:hover {
  background: #C80432;
  outline: none;
  border: 1px solid  #C80432;
  box-shadow: none;
}

button.sui-search-box__submit.search-btn:active,
button.sui-search-box__submit.search-btn:focus {
  background: #C80432;
  outline: none;
  border: 1px solid #C80432;
  box-shadow: none;
}

button.sui-search-box__submit.search-btn .searchIcon {
  fill: #fff;
}
.autocomplete .sui-search-box__text-input {
  box-shadow: none;
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
}

.individualResult{
  display: flex;
  flex-direction: row;
  margin: 24px 0;
  border-bottom: #E9ECEF 1px solid;
  min-height: 200px;
}

img.thumb{
  max-width: 200px;
  margin:0 1em 1em 0;
  border-radius: 6px;
  height: fit-content;
}


span.highlight{
  font-weight: 700;
}

.url{
  font-size: 12px;
  color: #008FCC;
  padding: 0;
  margin: 0;
}

.individualResult h4 {
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  color: #23323C;
  margin-bottom: 0;
  padding:0;

}

.individualResult h4 a{
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  color: #23323C;
  margin: 0;
  padding:0;

}

.individualResult p.snippet{
  padding: 1em 0;
  margin: 0;
}

.individualResult .details p{
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  margin:0
}

/* pagination */
 ul.rc-pagination {
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-bottom: 0;
}

.rc-pagination-item {
  border-radius: 0;
  color: #343741;
}

 .sui-paging .rc-pagination-item a{
  font-weight: normal;
}



 ul.rc-pagination li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding:8px 16px;
  text-indent: 0;
  margin: 0;
  font-weight: normal;
}

 ul.rc-pagination li::before{
  content: none;
}

 a.rc-pagination-item-link {
  text-decoration: none;
  font-weight: normal;
}
.rc-pagination-item {
  border-radius: 6px;
  border: none;
}

.rc-pagination-item:hover {
  background: #f8f8f8 !important;
  color: #343741 !important;
}

 .rc-pagination-item-active,.rc-pagination-item-active:hover,.rc-pagination-item-active:hover a {
  background: #C80432 !important;
  color: #f8f8f8 !important;
  border: 0px solid #C80432;
  border-radius: 6px;
}

 /* .rc-pagination-item-active:hover a {
  background: #f8f8f8 !important;
  color: #343741 !important;
  border: none;
  border-radius: 6px;
} */

 .rc-pagination-prev,
 .rc-pagination-next,
 .rc-pagination-jump-prev,
 .rc-pagination-jump-next {
  border-radius: 0;
}

 .rc-pagination-next,
 .rc-pagination-prev {
  width: 18px;
  height: 18px;
  float: none;
}

 .rc-pagination-next {
  background: #fff url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14'  fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.982144 13.0425C0.682521 12.7565 0.67148 12.2818 0.957485 11.9821L5.54839 7.17262C5.64061 7.07601 5.64061 6.92399 5.54839 6.82738L0.957484 2.01786C0.67148 1.71823 0.68252 1.24349 0.982144 0.957482C1.28177 0.671478 1.75651 0.682519 2.04252 0.982142L6.63343 5.79167C7.27893 6.46791 7.27893 7.53209 6.63343 8.20833L2.04252 13.0179C1.75651 13.3175 1.28177 13.3285 0.982144 13.0425Z' fill='%23545454'/%3E%3C/svg%3E%0A") no-repeat center !important;
}

 .rc-pagination-prev {
  background: #fff url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14'  fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.01786 13.0425C7.31748 12.7565 7.32852 12.2818 7.04252 11.9821L2.45161 7.17262C2.35939 7.07601 2.35939 6.92399 2.45161 6.82738L7.04252 2.01786C7.32852 1.71823 7.31748 1.24349 7.01786 0.957482C6.71823 0.671478 6.24349 0.682519 5.95748 0.982142L1.36657 5.79167C0.721069 6.46791 0.721069 7.53209 1.36657 8.20833L5.95748 13.0179C6.24349 13.3175 6.71823 13.3285 7.01786 13.0425Z' fill='%23545454'/%3E%3C/svg%3E%0A") no-repeat center !important;
}

 .rc-pagination-next a:after,
 .rc-pagination-prev a:after {
  content: none;
}

 .sui-paging .rc-pagination-item:hover a,
 .sui-paging .rc-pagination-jump-next:hover a {
  color: #C80432;
}

 .sui-paging .rc-pagination-jump-next:hover:after {
  color: #C80432;
  margin-bottom: 5px;
}

/*content styles*/
li.part-number{
  padding:24px 0;

}
li.part-number::before{
  content:"Part number: ";
  color:#C80432;
  font-weight: bold;

}

li.category{
  color:#C80432;
  font-weight: bold;
}

li h1{
  font-size: 32px;
  font-weight: bold;
  padding:0.25em 0;
  margin:0

}
h2{
  font-weight: 700;
  font-size: 28px;
  padding-bottom: 16px;
  color: #242B30;
  border-bottom: #C80432 1px solid;
}
h3{
  font-weight: 700;
  font-size: 20px;
  padding:12px 0;
  color: #242B30;

}
h4{
  font-weight: 700;
  font-size: 18px;
  padding: 12px 0;
  color: #4B4B4E;

}
h4.muted{
 
  color: #4b4b4eab;

}

/*facets*/
.sui-facet {
  font-family: 'Mulish', sans-serif;
  border: 1px solid #B8B8B8;
border-radius: 6px;
background: #FFFFFF;
padding: 8px 16px;
}

.sui-facet + .sui-facet{
  margin-top: 16px;
}

.sui-facet__title {
  font-weight: 700;
font-size: 18px;
color: #211E1F;
text-transform: none;
padding-bottom: 10px;
border-bottom:  1px solid #E8E8E8;
}

.sui-multi-checkbox-facet__checkbox{
  color: #DEE2E6;
  transform: scale(1.5);
  margin-right: 0.5rem;
}

.sui-multi-checkbox-facet label{
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #211E1F;
  margin:4px 0;
}

.sui-multi-checkbox-facet__option-input-wrapper{
  display: flex;
  align-items: center;
}
.sui-multi-checkbox-facet__option-count {
  color: #4B4B4E;
}

.large{
  font-size: larger;
}

ul.two-col {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

ul.four-col {
  columns: 4;
  -webkit-columns: 4;
  -moz-columns: 4;
}
ul.eight-col {
  columns: 8;
  -webkit-columns: 8;
  -moz-columns: 8;
}

ul.two-col li,ul.four-col li,ul.eight-col li  {
  list-style: none;
}

/*suggestions*/
 .sui-search-box__autocomplete-container.search-box {
  padding-bottom: 0px;
  padding-left: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
}




 ul.sui-search-box__suggestion-list {
  margin: 0;
  background: #fff;
  border-radius: 0%;
}


ul.sui-search-box__suggestion-list li em {
  margin: 0;
  color: #000;
  background: #fff;
  font-weight: 700;
}

 ul.sui-search-box__suggestion-list li {
  margin: 0;
  padding: 8px 10px 8px 33px;
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
  color: #4c4c4c;
  background-color: #fff;
  background-image:  url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9798 12.1869C12.175 12.3821 12.4916 12.3821 12.6869 12.1869C12.8821 11.9916 12.8821 11.675 12.6869 11.4798L11.9798 12.1869ZM10.5 5.5C10.5 7.98528 8.48528 10 6 10V11C9.03757 11 11.5 8.53757 11.5 5.5H10.5ZM6 10C3.51472 10 1.5 7.98528 1.5 5.5H0.5C0.5 8.53757 2.96243 11 6 11V10ZM1.5 5.5C1.5 3.01472 3.51472 1 6 1V0C2.96243 0 0.5 2.46243 0.5 5.5H1.5ZM6 1C8.48528 1 10.5 3.01472 10.5 5.5H11.5C11.5 2.46243 9.03757 0 6 0V1ZM12.6869 11.4798L9.89517 8.68806L9.18806 9.39517L11.9798 12.1869L12.6869 11.4798Z' fill='%23545454'/%3E%3C/svg%3E%0A");
  background-position: 10px 10px;
  background-repeat:  no-repeat ;

}

ul.sui-search-box__suggestion-list.content li {
  color: #4c4c4c;
  background-color: #fff;
  background-image:  url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9798 12.1869C12.175 12.3821 12.4916 12.3821 12.6869 12.1869C12.8821 11.9916 12.8821 11.675 12.6869 11.4798L11.9798 12.1869ZM10.5 5.5C10.5 7.98528 8.48528 10 6 10V11C9.03757 11 11.5 8.53757 11.5 5.5H10.5ZM6 10C3.51472 10 1.5 7.98528 1.5 5.5H0.5C0.5 8.53757 2.96243 11 6 11V10ZM1.5 5.5C1.5 3.01472 3.51472 1 6 1V0C2.96243 0 0.5 2.46243 0.5 5.5H1.5ZM6 1C8.48528 1 10.5 3.01472 10.5 5.5H11.5C11.5 2.46243 9.03757 0 6 0V1ZM12.6869 11.4798L9.89517 8.68806L9.18806 9.39517L11.9798 12.1869L12.6869 11.4798Z' fill='%23545454'/%3E%3C/svg%3E%0A");
  background-position: 10px 10px;
  background-repeat:  no-repeat ;

}

ul.sui-search-box__suggestion-list.signals li {
  margin: 0;
  padding: 8px 10px 8px 33px;
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
  color: #4c4c4c;
  background-color: #fff;
  background-image:  url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 13 13' fill='%234c4c4c' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9798 12.1869C12.175 12.3821 12.4916 12.3821 12.6869 12.1869C12.8821 11.9916 12.8821 11.675 12.6869 11.4798L11.9798 12.1869ZM10.5 5.5C10.5 7.98528 8.48528 10 6 10V11C9.03757 11 11.5 8.53757 11.5 5.5H10.5ZM6 10C3.51472 10 1.5 7.98528 1.5 5.5H0.5C0.5 8.53757 2.96243 11 6 11V10ZM1.5 5.5C1.5 3.01472 3.51472 1 6 1V0C2.96243 0 0.5 2.46243 0.5 5.5H1.5ZM6 1C8.48528 1 10.5 3.01472 10.5 5.5H11.5C11.5 2.46243 9.03757 0 6 0V1ZM12.6869 11.4798L9.89517 8.68806L9.18806 9.39517L11.9798 12.1869L12.6869 11.4798Z' fill='%234c4c4c'/%3E%3C/svg%3E%0A");
  background-position: 10px 10px;
  background-repeat:  no-repeat ;

}

ul.sui-search-box__suggestion-list.parts li {
  background-image: url('data:image/svg+xml,<%3Fxml version="1.0" %3F><svg width="16" height="16" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><title/><g data-name="49-Document-Financial" id="_49-Document-Financial"><path d="M28.71,6.29l-6-6A1,1,0,0,0,22,0H6A3,3,0,0,0,3,3V29a3,3,0,0,0,3,3H26a3,3,0,0,0,3-3V7A1,1,0,0,0,28.71,6.29ZM27,29a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V3A1,1,0,0,1,6,2H21V6a2,2,0,0,0,2,2h4Z"/><path d="M11,11a3,3,0,1,0,3,3A3,3,0,0,0,11,11Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,11,15Z"/><path d="M11,21a3,3,0,1,0,3,3A3,3,0,0,0,11,21Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,11,25Z"/><rect height="2" width="8" x="16" y="11"/><rect height="2" width="4" x="16" y="15"/><rect height="2" width="8" x="16" y="21"/><rect height="2" width="4" x="16" y="25"/></g></svg>') ;
  background-position: 10px 10px;
  background-repeat:  no-repeat ;
}

ul.sui-search-box__suggestion-list.product li {
background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><svg fill="%234c4c4c" width="16px" height="16px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg"><path d="M1703.534 960c0-41.788-3.84-84.48-11.633-127.172l210.184-182.174-199.454-340.856-265.186 88.433c-66.974-55.567-143.323-99.389-223.85-128.415L1158.932 0h-397.78L706.49 269.704c-81.43 29.138-156.423 72.282-223.962 128.414l-265.073-88.32L18 650.654l210.184 182.174C220.39 875.52 216.55 918.212 216.55 960s3.84 84.48 11.633 127.172L18 1269.346l199.454 340.856 265.186-88.433c66.974 55.567 143.322 99.389 223.85 128.415L761.152 1920h397.779l54.663-269.704c81.318-29.138 156.424-72.282 223.963-128.414l265.073 88.433 199.454-340.856-210.184-182.174c7.793-42.805 11.633-85.497 11.633-127.285m-743.492 395.294c-217.976 0-395.294-177.318-395.294-395.294 0-217.976 177.318-395.294 395.294-395.294 217.977 0 395.294 177.318 395.294 395.294 0 217.976-177.317 395.294-395.294 395.294" fill-rule="evenodd"/></svg>');
  background-position: 10px 10px;
  background-repeat:  no-repeat;
 
}

ul.sui-search-box__suggestion-list li:hover,
ul.sui-search-box__suggestion-list li[aria-selected="true"] {
  color: #545454;
  background-color: #efefef
}


 /* ul.sui-search-box__suggestion-list li:hover,
ul.sui-search-box__suggestion-list li[aria-selected="true"] {
  color: #545454;
  background: #E5E8E8
    url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9798 12.1869C12.175 12.3821 12.4916 12.3821 12.6869 12.1869C12.8821 11.9916 12.8821 11.675 12.6869 11.4798L11.9798 12.1869ZM10.5 5.5C10.5 7.98528 8.48528 10 6 10V11C9.03757 11 11.5 8.53757 11.5 5.5H10.5ZM6 10C3.51472 10 1.5 7.98528 1.5 5.5H0.5C0.5 8.53757 2.96243 11 6 11V10ZM1.5 5.5C1.5 3.01472 3.51472 1 6 1V0C2.96243 0 0.5 2.46243 0.5 5.5H1.5ZM6 1C8.48528 1 10.5 3.01472 10.5 5.5H11.5C11.5 2.46243 9.03757 0 6 0V1ZM12.6869 11.4798L9.89517 8.68806L9.18806 9.39517L11.9798 12.1869L12.6869 11.4798Z' fill='%23545454'/%3E%3C/svg%3E%0A")
    no-repeat 10px 10px;
  border-radius: 0;
} */



 ul.sui-search-box__suggestion-list li:hover em,
 ul.sui-search-box__suggestion-list li[aria-selected="true"] em {
  color:#545454;
  background:#efefef;
}

 .section-title {
  font-style: normal;
  color: #212529;
  font-weight: 600;
  font-size: 16px;
  text-transform: none;
  text-align: left;
  padding: 6px 0 6px 6px;
  margin-bottom: 3px !important;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  border-top: 1px solid #212529;
  border-bottom:1px solid #212529;
}
.section-title:first-child {
  
  border-top: 0 solid #212529;

}


.section-title.material.product::before{
  content: "inventory";
  padding-right: 0.25em;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  
 

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
  vertical-align:-14%;
 
  
}
.section-title.material.parts::before{
  content: "\E8B8";
  padding-right: 0.25em;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
 

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
  vertical-align:-14%;
  
}

.section-title.material.spelling::before{
  content:"\EB8B";
  padding-right: 0.25em;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
 

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
  vertical-align:-14%;
 
}
.section-title.material.signals::before{
  content:"\e8e5";
  padding-right: 0.25em;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
 

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
  vertical-align:-14%;
}


 .sui-select__control {
  border-radius: 0;
}
 ul.sui-search-box__suggestion-list li::before{
  content: none;
}
.autocomplete .sui-search-box__autocomplete-container{
  padding-bottom: 0;
  padding-top: 0;
}



/*Xref styles*/

#xref .sui-layout-sidebar{
  display: none
  }
  
  #xref .sui-layout-main{
    width: 100%;
  }
  #xref th{
    font-size: 0.9em;
  }

  /*tabs*/
  .nav-tabs{
    margin-left: 33%;
    padding-left: 32px;
    border: none;
    margin-top: 2rem;
    
  }
  .nav-tabs .nav-link{
   padding: 0.5rem 5rem;
  }

  .nav-tabs .nav-link.active{
    background: #C80432;
    color: #fff;
  }
  .sui-layout-header{
    display: none;
  }

  .sui-layout-body{
    border-top: 1px solid #eee;
    padding-top: 2rem;
  }